import React from 'react'
import { Layout, ContactBanner, Footer, Section } from '../components'

const Storaged = () => {
  return (
    <Layout>
      <Section title='storing:title' text='storing:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

export default Storaged
